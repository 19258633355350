import React from "react";
import {CloudflareImageParameters} from "../../@types/CloudflareImage";
import cfImageParametersToQuery from "../../utils/cfConfigToQuery";

interface CloudflareImageProps
    extends React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>,
        HTMLImageElement> {
    cloudflareParameters: CloudflareImageParameters;
    namespace: string;
}

export const CloudflareImage: React.FC<CloudflareImageProps> = props => {
        const {namespace, cloudflareParameters, src, ...imgProps} = props;

        return (
            <img alt={imgProps.alt}
                src={`https://${namespace}.bloomreach.io/cdn-cgi/image/${cfImageParametersToQuery(
                    cloudflareParameters
                )}/${src}`}
                {...imgProps}
            />
        );
    }
;
