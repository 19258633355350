import React, {Component} from "react";
import {CloudflareImageParameters, Gravity} from "./cfi/@types/CloudflareImage";
import {CloudflareImage} from "./cfi/components/CloudflareImage/CloudflareImage";
import Form from "@rjsf/material-ui";
import {JSONSchema7} from "json-schema";
import {Grid} from "@material-ui/core";
import {Container, Typography} from "@mui/material";
import {Focus, FocusPicker} from "image-focus";


interface State extends CloudflareImageParameters {
}

interface Props {
    imageUrl: string
    initialSettings?: CloudflareImageParameters
    onChange?: (data: CloudflareImageParameters) => any;
    namespace: string
}


const uiSchema = {
    quality: {
        "ui:widget": "range"
    },
    blur: {
        "ui:widget": "range"
    },
    background: {
        "ui:widget": "color"
    }

};

const paramSchema: JSONSchema7 = {
    type: "object",
    properties: {
        width: {
            type: "number",
        },
        height: {
            type: "number",
        },
        fit: {
            type: "string",
            enum: ["scale-down", "contain", "cover", "crop", "pad"],
        },
        background: {
            type: "string"
        },
        // gravity: {
        //     type: "string",
        //     enum: ["auto", "center", "left", "right", "top", "bottom"],
        // },
        // format: {
        //     type: "string",
        //     enum: ['', "auto"],
        // },
        quality: {
            type: "number",
            minimum: 10,
            maximum: 100,
            "multipleOf": 10
        },
        blur: {
            type: "number",
            minimum: 0,
            maximum: 250,
            "multipleOf": 5
        }

    }
}

const convertX = function (x: number) {
    return +0.5 * x + 0.5
}

const convertY = function (y: number) {
    return -0.5 * y + 0.5
}

const focus: Focus = {x: 0, y: 0}


export class ImageEditor extends Component<Props, State> {

    private focusPickerEl: React.RefObject<HTMLImageElement>;

    constructor(props: Readonly<Props> | Props) {
        super(props);
        this.state = props.initialSettings ? {...props.initialSettings} : {quality: 90, blur: 0, format: 'auto'}

        this.focusPickerEl = React.createRef();
    }

    componentDidMount() {
        if (!this.props.initialSettings) {
            const img = new Image();
            img.src = this.props.imageUrl
            new Promise(resolve => {
                img.onload = function () {
                    resolve(img);
                }
            }).then(() => {
                this.setState({width: img.naturalWidth, height: img.naturalHeight})
            });
        }
        if (this.focusPickerEl.current) {
            console.log('...is something')
            const focusPicker = new FocusPicker(this.focusPickerEl.current, {
                focus,
                onChange: (newFocus: Focus) => {
                    this.setState({gravity: {x: convertX(newFocus.x), y: convertY(newFocus.y)} as Gravity})
                },
            })
            console.log(focusPicker);
        }
    }

    render() {
        let initialSettings = {...this.state};

        return (
            <Grid container spacing={2}>
                <Grid item xs={10} style={{overflow: "scroll"}}>
                    <CloudflareImage style={{
                        maxWidth: '100%'
                    }} alt={'optimized'} src={this.props.imageUrl}
                                     cloudflareParameters={this.state}
                                     namespace={this.props.namespace}/>
                </Grid>
                <Grid item xs={2}>
                    <div style={{position: "sticky", top: 0}}>
                        <Container>
                            <Form
                                uiSchema={uiSchema}
                                formData={initialSettings}
                                schema={paramSchema as JSONSchema7}
                                onChange={({formData}) => this.setState({...formData}, () => this.props.onChange?.(formData))}>
                                <></>
                            </Form>
                            <div>
                                <Typography>Original - Focal Point:</Typography>
                                <div className="image-focus-picker-container">
                                    <div>
                                        <img style={{maxWidth: '100%'}} ref={this.focusPickerEl} id="focus-picker-img"
                                             alt={'original'}
                                             src={this.props.imageUrl} data-focus-x="0" data-focus-y="0"/>
                                    </div>
                                </div>
                            </div>
                        </Container>
                    </div>
                </Grid>
            </Grid>);
    }
}