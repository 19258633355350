import React, {useEffect} from 'react';
import {Button} from "@mui/material";
import {Grid} from "@material-ui/core";
import {DialogProperties, DialogSize, UiScope} from "@bloomreach/ui-extension";
import {CloudflareImage} from "./cfi/components/CloudflareImage/CloudflareImage";
import {CloudflareImageParameters} from "./cfi/@types/CloudflareImage";

interface Props {
    ui: UiScope
}

function Field({ui}: Props) {

    const [imageUrl, setImageUrl] = React.useState<string | undefined>(undefined)
    const [imageId, setImageId] = React.useState<string | undefined>(undefined);
    const [cloudflareParams, setCloudflareParams] = React.useState<CloudflareImageParameters>({});
    const [mode, setMode] = React.useState<string | undefined>(undefined);

    useEffect(() => {
        console.log('trigger..')
        ui.document.field.getValue('brxsaas:optimizedImage', 'brxsaas:image', 'hippo:docbase')
            .then((value: string) => {
                if (!imageId && value) {
                    setImageId(value);
                } else if (imageId !== value) {
                    setImageId(value);
                }
            })
        ui.document.field.getValue()
            .then((value: string) => {
                const cfp = value ? JSON.parse(value) : {}
                setCloudflareParams(cfp)
            })
        const interval = setInterval(() => {
            ui.document.get().then(doc => {
                setMode(doc.mode)
                if (doc.mode === 'edit') {
                    ui.document.field.getValue('brxsaas:optimizedImage', 'brxsaas:image', 'hippo:docbase').then((value: string) => {
                        if (!imageId && value) {
                            setImageId(value);
                        } else if (imageId !== value) {
                            setImageId(value);
                        }
                    })
                } else {
                    clearInterval(interval);
                }
            });
            console.log('check...');
        }, 2500);
        return () => clearInterval(interval);
    }, [imageId, ui.document]);

    useEffect(() => {
        if (imageId && !imageId.startsWith('cafebabe')) {
            fetch(ui.baseUrl.replace('cms/', `delivery/site/v1/channels/brxsaas/documents/${imageId}`))
                .then(response => response.json())
                .then(data => {
                    const content = data.content;
                    const item = content[Object.keys(content)[0]];
                    const url = item?.data?.original?.links?.site?.href ?? item.links.site.href
                    setImageUrl(url)
                });
            ui.document.field.setHeight(150);
        } else if (imageId && imageId.startsWith('cafebabe')) {
            setImageUrl(undefined);
            setCloudflareParams({})
            ui.document.field.setValue('{}');
            ui.document.field.setHeight(10);
        }
    }, [imageId, ui.baseUrl, ui.document.field])

    return (
        <Grid>
            <Grid item xs={4}>
                {imageUrl && <CloudflareImage style={{width: 100, height: 100, objectFit: "contain"}} alt={'optimized'}
                                              src={imageUrl}
                                              cloudflareParameters={cloudflareParams}
                                              namespace={'sandbox-sales08'}/>}
            </Grid>
            <Grid item xs={4}>
                {(imageUrl && mode && mode === 'edit') && <Button onClick={() => {
                    try {
                        ui.document.field.getValue().then(value => {
                            const parse = value ? JSON.parse(value) : {};
                            parse.imageUrl = imageUrl;
                            const dialogOptions: DialogProperties = {
                                title: 'Image Editor',
                                url: '/dialog',
                                size: DialogSize.Large,
                                value: JSON.stringify(parse),
                            };
                            ui.dialog.open(dialogOptions).then((response) => {
                                // @ts-ignore
                                const cfp: string = response
                                ui.document.field.setValue(cfp);
                                setCloudflareParams(JSON.parse(cfp))
                            });
                        });
                    } catch (error: any) {
                        if (error.code === 'DialogCanceled') {
                            return;
                        }
                        console.error('Error after open dialog: ', error.code, error.message);
                    }
                }}>Open editor</Button>}
            </Grid>
        </Grid>
    );
}

export default Field;
