import React from 'react';
import {Container, TextField} from "@mui/material";
import {ImageEditor} from "./ImageEditor";
import {Grid} from "@material-ui/core";

function App() {

    const [imageUrl, setImageUrl] = React.useState('https://images.pexels.com/photos/2486168/pexels-photo-2486168.jpeg')

    return (
        <Grid container spacing={2} style={{marginTop: 10}}>
            <Grid item xs={12}>
                <Container><TextField onChange={event => {
                    setImageUrl(event.target.value)
                }} fullWidth id="outlined-basic"
                                      label="Image Url" variant="standard" value={imageUrl}/></Container>
            </Grid>
            <Grid item xs={12}>
                <ImageEditor namespace={'sandbox-sales08'}  imageUrl={imageUrl}/>
            </Grid>
        </Grid>
    );
}

export default App;
