import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import UiExtension from "@bloomreach/ui-extension";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Field from "./Field";
import Dialog from "./Dialog";


document.addEventListener('DOMContentLoaded', async () => {
    try {
        const ui = await UiExtension.register();
        const routing = (
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Field ui={ui}/>}/>
                    <Route path="/dialog" element={<Dialog ui={ui}/>}/>
                </Routes>
            </BrowserRouter>
        );
        ReactDOM.render(routing, document.getElementById("root"));
    } catch (error: any) {
        console.log(error);
        console.error('Failed to register extension:', error.message);
        console.error('- error code:', error.code);
        ReactDOM.render(<App/>, document.getElementById('root'));
    }
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
