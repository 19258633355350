import React, {useEffect} from 'react';
import {AppBar, Box, Button, Grid, Toolbar, Typography} from "@mui/material";
import {ImageEditor} from "./ImageEditor";
import {UiScope} from "@bloomreach/ui-extension";
import {CloudflareImageParameters} from "./cfi/@types/CloudflareImage";

interface Props {
    ui: UiScope
}

function Dialog({ui}: Props) {

    const [imageUrl, setImageUrl] = React.useState<string | undefined>(undefined);
    const [cloudflareParams, setCloudflareParams] = React.useState<CloudflareImageParameters | undefined>(undefined);
    const [namespace, setNamespace] = React.useState('sandbox-sales08');
    const [widthAndHeight, setWidthAndHeight] = React.useState<string>('');


    useEffect(() => {
        const img: HTMLImageElement = new Image();
        // @ts-ignore
        img.src = imageUrl;
        new Promise(resolve => {
            img.onload = function () {
                resolve(img);
            }
        }).then(() => setWidthAndHeight(`original width:${img.naturalWidth} | original height:${img.naturalHeight}`));
    }, [imageUrl])

    useEffect(() => {
        ui.dialog.options().then(options => {
            const parse = JSON.parse(options.value);
            const imageUrl = parse.imageUrl;
            setImageUrl(imageUrl);
            delete parse.imageUrl;
            setCloudflareParams(parse);
            const re = new RegExp('/(?:http[s]*\\:\\/\\/)*(.*?)\\.(?=[^\\/]*\\..{2,5})/i');
            // @ts-ignore
            const namespace = re.exec(ui.baseUrl)[0];
            setNamespace(namespace);
        });
    }, [ui.dialog, ui.baseUrl])

    return (
        <Grid container spacing={2}>
            <Box sx={{flexGrow: 1}}>
                <AppBar position="static" color={"transparent"} variant={"outlined"}>
                    <Toolbar>
                        <Typography style={{marginTop: '10px'}} variant="h6" sx={{flexGrow: 1}}>
                            {widthAndHeight}
                        </Typography>
                        <Button style={{marginTop: '10px'}} variant={"outlined"} color={"secondary"}
                                onClick={() => ui.dialog.close(JSON.stringify(cloudflareParams))}>Save</Button>
                    </Toolbar>
                </AppBar>
            </Box>
            <Grid item xs={12}>
                {(imageUrl && cloudflareParams) &&
                <ImageEditor namespace={namespace}
                             initialSettings={(Object.keys(cloudflareParams).length !== 0) ? cloudflareParams : undefined}
                             onChange={(data) => setCloudflareParams(data)}
                             key={imageUrl} imageUrl={imageUrl}/>}
            </Grid>
        </Grid>
    );
}

export default Dialog;
